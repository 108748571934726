import { ReactElement, useEffect, useState } from 'react';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';
import { useTranslation } from 'react-i18next';

import Fonts from '@/components/shared/Fonts';
import { ErrorBoundary } from '@/components/shared/ErrorBoundary';
import { PreferencesProvider } from '@/contexts/PreferencesContext';
import { KeysProvider } from '@/contexts/KeysContext';

import '@/i18n';
import { IBrandData } from '../interfaces/IBrandData';
import { useTheme } from '../hooks/useTheme';
import { Session } from 'next-auth';
import { GetServerSidePropsContext } from 'next';
import { IAppProps } from '../interfaces/IAppProps';
import { IUserData } from '../interfaces/IUserData';
import { logger } from '../utils/logger';
import { BugsnagErrorBoundary } from '../utils/BugsnagErrorBoundary';
import { notify } from '../lib/bugsnag';
import { Debug } from '../components/shared/Debug';
import { IDebugData } from '../interfaces/IDebugData';
import { Brand } from '../enums/Brand';
import { getUserData } from '../utils/getUserData';
import { useRouter } from 'next/router';

const App = ({ Component, brand, session, userData, debug, log }: AppProps & IAppProps): ReactElement | null => {
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();
  const { reload } = useRouter();

  useEffect(() => {
    setReady(true);

    if (debug && log) {
      console.group('%cDebug log', 'font-weight:bold;font-size:16px;color:red');
      log.forEach(console.log);
      console.groupEnd();
    }
  }, [debug, log]);

  const showSalary = userData?.meta?.showSalary ?? true;
  const theme = useTheme(brand?.brand);
  const pageTitle = brand?.brand === Brand.Infinum ? t('common.title2') : t('common.title');

  useEffect(() => {
    if (!brand) {
      reload();
    }
  }, [brand, reload]);

  if (!ready) {
    return null;
  }

  const hydratizedUserData = userData?.grant
    ? {
        ...userData,
        grant: {
          ...userData.grant,
          historicalValue: userData.grant.historicalValue
            .map((item) => ({
              ...item,
              date: new Date(item.date),
            }))
            .filter((item) => item.date.getTime() < Date.now()),
        },
      }
    : userData;

  if (!brand) {
    return null;
  }

  return (
    <BugsnagErrorBoundary>
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <ColorModeProvider>
            <Fonts brand={brand.brand} />
            <Head>
              <title>{pageTitle}</title>
            </Head>
            <KeysProvider brand={brand.brand} brandData={brand} userData={hydratizedUserData} showSalary={showSalary}>
              <PreferencesProvider>
                <ErrorBoundary>
                  {debug && <Debug />}
                  <Component session={session} />
                </ErrorBoundary>
              </PreferencesProvider>
            </KeysProvider>
          </ColorModeProvider>
        </ChakraProvider>
      </SessionProvider>
    </BugsnagErrorBoundary>
  );
};

App.getInitialProps = async function ({ ctx }: AppContext) {
  const log: Array<string | null | object> = [];
  let props: {
    brand?: IBrandData;
    session?: Session;
    userData: IUserData;
    showSalary: boolean;
    log?: Array<string | null | object>;
    debug?: boolean;
  } = { userData: {}, showSalary: false };

  try {
    if (ctx.req && ctx.res) {
      const { getServerSideProps } = await import('@/utils/auth');
      const { props: serverProps } = await getServerSideProps(ctx as unknown as GetServerSidePropsContext);

      props = {
        ...props,
        ...(serverProps as unknown as { brand: IBrandData; session?: Session }),
      };

      const session = props?.session;

      const start = Date.now();

      if (session?.user?.email && props?.brand?.brand === 'infinum') {
        let debugOptions: IDebugData = {};

        try {
          debugOptions = JSON.parse(
            decodeURIComponent(
              ctx.req?.headers?.cookie
                ?.split?.(';')
                ?.find?.((c) => c?.trim?.()?.startsWith?.('__debug__='))
                ?.slice?.(11) ?? '',
            ),
          ) as IDebugData;
        } catch (e) {
          // Ignore
        }

        try {
          const userData = await getUserData(session, debugOptions);

          log.push(debugOptions, userData);

          props.userData = userData;
        } catch (e) {
          notify(e as Error);

          throw e;
        }
      }
      const end = Date.now();

      if (props.userData?.meta?.isSuperAdmin) {
        props.debug = true;
        log.push(`Auth: ${props.session?.auth}`);
        props.log = log;
      }

      if (!props.userData?.meta?.isBetaUser) {
        delete props.userData?.compensation;
      }

      if (props.session) {
        delete props.session.auth;
      }

      logger.info('Grant time: ' + (end - start).toFixed(2) + 'ms');
    } else {
      const propsReq = await fetch('/api/info');

      props = await propsReq.json();
    }

    return props;
  } catch (e) {
    notify(e as Error);

    // throw e;
    return props;
  }
};

export default App;
